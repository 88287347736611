<template>
  <div>
    <v-col class="mx-1 my-2  ">
      <v-card>
        <v-row class="px-2 py-2">
          <v-spacer />

          <v-col
            cols="12"
            sm="4"
            md="3"
            lg="2"
            xl="2"
            class="  py-0 my-0 mb-2"
          >
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="startDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{on, attrs}">
                <v-text-field
                  v-model="startDate"
                  label="Tarih"
                  prepend-icon="mdi-calendar"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="startDate"
                no-title
                scrollable
              >
                <v-spacer />
                <Button
                  :text="true"
                  color="primary"
                  @click="menu = false"
                >
                  Kapat
                </Button>
                <Button
                  :text="true"
                  color="primary"
                  @click="$refs.menu.save(startDate)"
                >
                  Seç
                </Button>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col
            cols="12"
            sm="5"
            md="3"
            lg="2"
            xl="2"
            class="  py-0 my-0 mb-2"
          >
            <Select
              v-model="santralModel"
              :items="santralList"
              label="Santral"
              outlined
              dense
            />
          </v-col>

          <v-col
            cols="6"
            sm="2"
            md="2"
            lg="2"
            xl="1"
            class="  py-0 my-0  mb-2"
          >
            <Button
              color="primary"
              @click="setData"
            >
              Filtrele
            </Button>
          </v-col>
        </v-row>

        <v-col
          v-if="santralModel && stringboxNameList[santralModel]"
          cols="12"
        >
          <Card
            v-for="string in stringboxNameList[santralModel]"

            :key="'santral_dccurrent_' + string.value"
            class="blue-grey lighten-5"
          >
            <SantralStringsComparison
              v-if="stringboxNameList[santralModel] && santralData[santralModel]"
              :key="string.text"
              :santral="santralData[santralModel]"
              :string="string"
              :dc-current="dcCurrent[string.value]"
              :start-date="startDate"
              :query="query"
              class="mt-4"
            />
          </Card>
        </v-col>
      </v-card>
    </v-col>
  </div>
</template>

<script>

import moment from 'moment';
import SantralStringsComparison from '@/views/components/Stringbox/SantralStringsComparison.vue';
import helper from '@/api/helper';

export default {
  components: {
    SantralStringsComparison,
  },
  data() {
    return {
      measureList: [],
      santral_items: [],
      startDate: moment().format('YYYY-MM-DD'),
      finishDate: moment().format('YYYY-MM-DD'),
      menu: false,
      modal: false,
      menu2: false,
      measures: [],
      measureSelect: 'DCCurrent',
      componentKey: 1,
      title: '',
      santralModel: '',
      inverterList: [],
      height: '',
      HeatmapData: [],
      stringboxList: {},
      stringboxNameList: {},
      stepTime: 5,
      dcCurrent: {},
      query: false,
    };
  },
  computed: {
    santralList() {
      const { santralRawData } = this.$store.getters;
      if (helper.is_Empty(santralRawData)) return;
      const santralList = [];
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties

      santralRawData.forEach((item) => {
        santralList.push({
          text: item.santral,
          value: item.santralCode,
        });
      });

      // eslint-disable-next-line consistent-return
      return santralList;
    },
    santralData() {
      const { santralRawData } = this.$store.getters;
      if (helper.is_Empty(santralRawData)) return;
      const santralData = {};
      santralRawData.forEach((item) => {
        santralData[item.santralCode] = item;
      });
      // eslint-disable-next-line consistent-return
      return santralData;
    },
  },
  watch: {
    santralModel() {
      this.setData();
    },
    santralList() {
      if (this.santralList.length > 0) {
        this.santralModel = this.santralList[0].value;
      }
    },
  },
  mounted() {
    // eslint-disable-next-line no-use-before-define
    const myVar1 = setInterval(myTimer1, 1000);
    const self = this;

    function myTimer1() {
      const { santralStringboxData } = self.$store.getters;

      if (santralStringboxData !== undefined && Object.keys(santralStringboxData).length > 0) {
        clearInterval(myVar1);

        const dcCurrent = {};
        const stringboxNameList = {};
        const stringboxList = {};

        Object.keys(santralStringboxData).forEach((s) => {
          // eslint-disable-next-line no-plusplus
          stringboxList[s] = Object.keys(santralStringboxData[s]);
          stringboxNameList[s] = [];

          Object.keys(santralStringboxData[s]).forEach((i) => {
            const stringbox = santralStringboxData[s][i].STRINGBOX;
            dcCurrent[stringbox] = [];
            stringboxNameList[s].push({
              value: stringbox,
              text: santralStringboxData[s][i].STRINGBOXNAME,
            });

            const gMeasure = santralStringboxData[s][i].general_measure_list;
            Object.keys(gMeasure).forEach((k) => {
              if (
                gMeasure[k].value.indexOf('DCCurrent') > -1
                && gMeasure[k].value !== 'DCCurrent'
              ) {
                dcCurrent[stringbox].push(gMeasure[k].text);
              }
            });
          });
        });

        self.stringboxList = stringboxList;
        self.stringboxNameList = stringboxNameList;
        self.dcCurrent = dcCurrent;
      }
    }
  },
  methods: {
    setData() {
      this.query = !this.query;
    },
  },
};
</script>

<style scoped>
.svgclass {
  overflow-x: auto;
  width: 100%;
  height: 375px;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
