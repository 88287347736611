<template>
  <div
    v-if="chartId"
    :id="chartId"
  />
</template>

<script>
import moment from 'moment';
import * as Heatmap from '@/api/CombinerBox/Heatmap';
import ApexCharts from 'apexcharts';

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['santral', 'string', 'dcCurrent', 'query', 'startDate'],
  data() {
    return {
      measureSelect: 'DCCurrent',
      title: '',
      height: '',
      stepTime: 5,
      chartId: '',
      svgclass: {
        'overflow-x': 'auto',
        width: '99%',
      },
    };
  },
  computed: {
  },
  watch: {
    query() {
      this.setChartHtmlElement();
      this.setData();
    },
  },
  created() {
  },
  mounted() {
    this.setChartHtmlElement();
    this.setData();
  },
  methods: {
    setChartHtmlElement() {
      this.chartId = `santral_dccurrent_${this.string.value}`;
      const elemnet = document.getElementById(this.chartId);
      if (elemnet !== null) {
        elemnet.innerHTML = '';
      }
      const itemlenght = this.dcCurrent.length;
      let height = itemlenght * 24;
      if (height < 300) {
        height += 100;
      }
    },
    setData() {
      this.stepTime = 5;
      const stepTime = this.stepTime * 2;
      const MinuteGrup = [];
      for (let mm = this.stepTime; mm < 60; mm += stepTime) {
        MinuteGrup.push(mm);
      }

      const formdata = {
        condiniton: {
          santral: this.santral.santral,
          startDate: this.startDate,
          finishDate: this.startDate,
          stringbox: this.string.value,
          maxcount: 3000,
          MinuteGrup,
          measures: this.dcCurrent,
        },
      };

      const temp = Heatmap.default.heatmapDailyMeanStringItemDiff(formdata, this.dcCurrent);
      temp.then((data) => {
        this.heatmaps(data);
      });
    },
    heatmaps(data) {
      const unit = 'A';
      const parameter = {
        chartId: this.chartId,
        chartXkey: 'time',
        chartYkey: 'DCCurrent',
        measureSelect: 'dc_current',
        text: 'Üretim',
        unit,
        date: moment(this.startDate).format('DD.MM.YYYY'),
        santral: this.santral.santral,
        string: this.string.text,
      };

      function getCellColor(item) {
        const yuzde = (item.mean * 90) / 100;
        if (item.value > 0 && item.value < 1) {
          return 1;
        } if (item.value > 1 && item.value < yuzde) {
          // eslint-disable-next-line no-mixed-operators
          return 2;
        }
        if (item.value > yuzde) {
          // eslint-disable-next-line no-mixed-operators
          return 3;
        }
        return 1;
      }
      // eslint-disable-next-line no-shadow
      function generateData(dcCurrent, data) {
        const generatedData = [];
        data.forEach((item) => {
          const val = getCellColor(item);
          if (dcCurrent === item.dc_current) {
            generatedData.push({
              x: item.time,
              y: val,
              mean: item.mean,
              value: item.value,
            });
          }
        });

        return generatedData;
      }
      const itemlenght = this.dcCurrent.length;

      const height = itemlenght * 24;
      const options = {
        series: [],
        chart: {
          height,
          width: '99%',
          type: 'heatmap',
        },
        dataLabels: {
          enabled: false,
        },
        chartInfo: parameter,
        plotOptions: {
          show: false,
          heatmap: {
            colorScale: {
              ranges: [
                {
                  from: 0,
                  to: 1,
                  color: '#f03a2e',
                },
                {
                  from: 2,
                  to: 2,
                  color: '#FF8F00',
                },
                {
                  from: 3,
                  to: 3,
                  color: '#32993b',
                },
              ],
            },
          },
        },
        stroke: {
          show: true,
          colors: ['#fff'],
          width: 1,
        },
        xaxis: {
          type: 'category',
        },
        yaxis: {
          show: false,
        },
        title: {
          text: `${this.santral.santral} santrali ${this.string.text} Üretim karşılaştırması`,
        },
        tooltip: {
          custom({ seriesIndex, dataPointIndex, w }) {
            return '<div style="padding: 10px; color: #000; background-color: #fff;">'
              + `<span> Tarih : ${w.config.chartInfo.date}</span><br/>`
              + `<span> Saat : ${w.globals.labels[dataPointIndex]}</span><br/>`
              + `<span> Santral : ${w.config.chartInfo.santral}</span> <br/>`
              + `<span> Stringbox : ${w.config.chartInfo.string}</span> <br/>`
              + `<span> String : ${w.config.series[seriesIndex].name}</span> <br/>`
              + `<span> ${w.config.chartInfo.text} : ${w.config.series[seriesIndex].data[dataPointIndex].value.toFixed(2)} ${w.config.chartInfo.unit}</span><br/> `
              + `<span> Ortalama: ${w.config.series[seriesIndex].data[dataPointIndex].mean.toFixed(2)} ${w.config.chartInfo.unit}</span>`
              + '</div>';
          },
        },
      };

      const { clientWidth } = document.documentElement;

      if (clientWidth >= 768) {
        options.xaxis = {
          show: false,
        };
        options.yaxis = {
          show: true,
        };
      }

      Object.keys(this.dcCurrent).forEach((i) => {
        const obj = {};
        obj.name = this.dcCurrent[i];
        obj.data = generateData(this.dcCurrent[i], data);
        options.series.push(obj);
      });

      const k = `#${this.chartId}`;
      const chart = new ApexCharts(document.querySelector(k), options);
      chart.render();
    },
  },
};
</script>

<style scoped>
.svgclass {
  overflow-x: auto;
  width: 95%;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
